body {
    font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
}

p {
    padding: 0;
    font-size: 16px;
}

.no-scroll {
    overflow: hidden;
  }
  

.Blue_cl {
    color: #388ae9;
}

.Green_cl {
    color: #028e00;
}

.Blue_bg {
    background-color: #388ae9;
}


.Heading {
    margin: 0;
    font-size: 50px;
    font-weight: bold;
    padding: 0;
    text-align: center;
}


/* Modal.css */
.modal-overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.6);
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 1000;
  }
  
  .modal-content {
    background: #e8f3ff;
    padding: 40px 40px;
    border-radius: 8px;
    width: auto;
    margin-inline: 20px;
    position: relative;
  }
  