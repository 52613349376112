.container-fluid {
    display: flex;
    justify-content: center;
    overflow: auto;
    padding: 0;
    text-align: left;
}

.content-container {
    max-width: 1000px;
    width: 100%;
    padding: 15px;
}

.code {
    background-color: #f0f0f0;
    padding: 10px;
    border-radius: 5px;
    margin-bottom: 15px;
    border: 10px;
}

.btn {
    border: 2px solid #0794ff;
    width: 100px;
    font-weight: bold;
    margin-inline: 15px;
}

.container {
    max-width: 1000px;
    margin: 50px auto;
}

.QuestionContainer {
    margin: 10px 0;
    background-color: #f0f0f0;
}

.question {
    display: flex;
    justify-content: space-between;
    align-items: center;
    background-color: #f0f0f0;
    padding: 10px;
    cursor: pointer;
    border-radius: 5px;
}

.QuestionText {
    font-size: 17px;
    font-weight: 500;
}

.question.disabled {
    cursor: default;
}

.answer {
    display: none;
    padding: 10px;
    margin: 5px 0 15px 0;
    border-left: 5px solid #0bd225;
    background-color: #d7ffd6;
    border-radius: 5px;
}

.icon {
    font-size: 20px;
}

.check {
    color: green;
}