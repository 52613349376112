    body {
        font-family: sans-serif;
    }

    .outline {
        color: rgb(225, 246, 255);
        font-size: 25px;
        font-weight: bold;
        letter-spacing: 1px;
        -webkit-text-stroke-width: 2px;
        -webkit-text-stroke-color: black;
    }

    .HeadingText {
        font-size: 25px;
        font-weight: bold;
        text-align: left;
        margin: 0;
        margin-left: 80px;
        margin-top: 50px;
    }

    .MainText {
        text-transform: capitalize;
        color: black;
        font-size: 9vh;
        font-weight: bold;
        text-align: center;

    }



    #Amigos {
        height: 100%;
        padding: 10px 50px;
    }

    .AmigoText {
        padding: 0;
        text-align: left;
        font-size: 16px;
        letter-spacing: 1;
        font-family: sans-serif;
    }

    .Subscript {
        color: rgb(255, 64, 64);
        font-size: 18px;
        font-family: 'Dancing Script', cursive;
        font-weight: bold;
        word-spacing: -3px;
        letter-spacing: 0px;
        -webkit-text-stroke-width: 0px;
        -webkit-text-stroke-color: transparent;
    }

    .barGraph {
        background-color: rgb(25, 131, 252);
        width: 50px;
        border-radius: 5px;
        align-self: flex-end;
    }

    .AmigoContainer {
        background-color: #E1F6FF;
        height: '100%';
        border-radius: 20px;
        margin: 0px 20px;
        padding: 5px 40px;
        box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2);
    }

    .ListStyle {
        list-style-type: none;
    }

    .ListStyle li {
        background-image: url('../assets/right-arrow.png');
        background-repeat: no-repeat;
        padding-left: 30px;
        background-position: 0 3px;
    }

    .ListStyle li {
        text-align: left;
        margin-bottom: 5px;
    }

    .graph-Container {
        display: flex;
        justify-content: space-between;
        padding-inline: 30px;
        align-items: end;
        width: 100%;
    }


    .Web-graph-image {
        align-self: flex-end;
    }

    .graph-text {
        color: #0f8bff;
        padding: 0;
        margin-left: 30px;
        font-weight: bold;
        font-size: 20px;
    }

    .Image {
        display: none;
    }

    .graph-Text-Mobile {
        display: flex;
        align-items: center;
    }

    .graph-Icon-Text-Container {
        width: 300px;
        display: flex;
    }


    .Collage {
        padding: 10px;
    }

    .PointsContainer {
        list-style: none;
        text-align: center;
        margin-block: 50px;
        /* margin-inline: 200px; */
    }

    .PointsContainer li {
        color: #636363;
        margin: 10px;
        font-weight: bold;
        letter-spacing: 1px;
    }

    .ListItem {
        /* background-color: #c3eeff; */
        border: 1px solid gray;
        padding-block: 30px;
        border-radius: 5px;
        display: flex;
        justify-content: center;
        padding-inline: 30px;
        align-items: center;
        margin: 10px;
        margin-inline: 200px;
    }


    #SWE {
        height: 100vh;
        margin-top: 30px;
    }

    .ButtonContainer {
        display: flex;
        justify-content: center;
        padding: 5px;
        transition: transform 0.3s ease-in-out;
    }

    .ButtonText {
        font-weight: bold;
        text-decoration-line: none;
        background-color: #148bec;
        padding: 5px 20px;
        border-radius: 5px;
        color: white;
    }

    .ButtonContainer:hover {
        transform: scale(1.05);
    }

    .ButtonText:hover {
        text-decoration-line: none;
        color: white;
    }

    .vision-wrapper-web {
        display: flex;
        justify-content: flex-end;
        max-width: 1200px;
        margin: 0 auto;
        gap: 20px;
        max-height: 100vh;
        padding: 50px 50px;

    }

    .vision-wrapper-mobile {
        display: none;
        padding: 15px;
    }

    .vision-left-container {
        width: 65%;
        border-radius: 10px;
        box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
        border: 2px solid rgb(235, 235, 235);
    }

    .vision-left-container h4 {
        text-align: left;
        margin: 0;
    }

    .vision-Heading {
        background-color: #caefff;
        padding: 15px 50px;
        border-radius: 10px 10px 0px 0px;
    }

    .Vision-Content-container {
        display: flex;
        flex-direction: column;
        gap: 20px;
    }

    .vision-content {
        font-size: 17px;
        letter-spacing: 0.4px;
        word-spacing: 3px;
        line-height: 28px;
        margin: 10px 50px 20px 50px;
        text-align: left;
    }

    .GreenText {
        color: #00ac00;
        font-weight: 600;
    }

    .BlueText {
        color: #388ae9;
        font-weight: 600;
    }

    .vision-item {
        border: 2px solid #caefff;
        padding: 18px;
        border-radius: 8px;
        box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
        margin-top: 20px;
        cursor: pointer;
        display: flex;
    }

    .vision-item p {
        margin: 0;
        text-align: left;
        font-weight: bold;
    }

    .vision-item {
        transition: opacity 0.5s, border 0.5s;
    }


    .vision-item.dimmed {
        opacity: 0.3;
    }

    .vision-item.highlight {
        opacity: 1;
        background-color: #caefff;
    }


    /* footer styles */

    .footer {
        background-color: rgb(49, 51, 54);
        padding-top: 30px;
    }

    .footer p {
        margin: 0;

    }

    .footer-heading {
        color: #148bec;
        font-weight: bold;
        text-align: left;
        padding-bottom: 10px;
        font-size: 20px;
    }

    .footer-content {
        display: flex;
        justify-content: space-around;
        max-width: 1200px;
        margin: 0 auto;
    }

    .contact-info {
        flex: 1;
        margin: 0px 20px;
        padding: 10px 10px;
        max-width: 30%;
    }

    .contact-info p {
        text-align: left;
        color: white;
    }

    .About-info {
        flex: 1;
        padding: 10px 10px;
        max-width: 40%;
    }


    .Footer-Links {
        margin: 0px 20px;
        padding: 10px 10px;
        min-width: 20%;
    }

    .Footer-Links ul {
        list-style: none;
        padding: 0;
        margin: 0;
    }

    .Footer-Links li {
        padding: 5px 0px;
        text-align: left;
        text-decoration: none;
        color: white;
        font-size: 16px;
        font-weight: bold;
        cursor: pointer;

    }

    .Footer-Links li:hover {
        color: #148bec;
    }

    .Footer-Links a {
        padding: 5px 0px;
        text-align: left;
        text-decoration: none;
        color: white;
        font-size: 16px;
        font-weight: bold;
        cursor: pointer;

    }

    .Footer-Links a:hover {
        color: #148bec;
    }

    .legal-info {
        border-top: 2px solid white;
        margin-inline: 100px;
        padding: 20px;
    }



    @media only screen and (max-width: 600px) {


        .menu-toggle {
            display: block;
            position: fixed;
            right: 20px;
            top: 7px;
            font-size: 20px;
        }

        nav {
            display: none;
            text-align: center;
            max-height: 0;
            overflow: hidden;
            right: 0;
        }

        nav.active {
            display: flex;
            flex-direction: column;
            position: fixed;
            right: 0;
            height: 100vh;
            width: 40%;
            background-color: rgb(233, 247, 255);
            z-index: 10;
            max-height: 100vh;
            overflow-y: auto;
            padding-top: 50px;
        }

        nav a {
            padding: 15px;
            font-size: 18px;
            border-bottom: 1px solid #ddd;
            transition: background-color 0.3s ease;
            margin-bottom: 10px;
        }

        .MainText {
            font-size: 6vh;
        }

        .HeadingText {
            margin-left: 20px;
        }

        .Image {
            display: block;
        }

        /* amigo related styles */

        #Amigos {
            height: 100%;
            padding: 0;
        }

        .AmigoContainer {
            height: 100%;
            border-radius: 10px;
            margin: 0px 15px;
            padding: 5px 15px;
            box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2);
        }


        .graph-Container {
            display: flex;
            flex-direction: column;
            padding-inline: 0px;
            width: 100%;
            align-items: center;
        }

        .graph-text {
            margin-left: 10px;
        }

        .barGraph {
            display: none;
        }

        .Web-graph-image {
            display: none;
        }

        .AmigoText {
            font-size: 18px;
        }

        .ListStyle li {
            text-align: left;
            font-size: 18px;
        }

        .ListItem {
            margin-inline: 10px;
        }

        /* Swe styles */
        #SWE {
            height: 100%;
        }



        /* vision related styles */

        .vision-wrapper-web {
            display: none;
        }

        .vision-wrapper-mobile {
            display: flex;
        }

        .vision-left-container {
            width: 100%;
            border-radius: 10px;
            box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
            border: 2px solid rgb(235, 235, 235);
            height: 100%;
        }

        .vision-item p {
            font-size: 18px;
        }


        .Vision-Content-container {
            gap: 5px;
        }

        .vision-item.highlight {
            opacity: 1;
            background-color: white;
        }


        .vision-Heading {
            padding: 15px 20px;
            border-radius: 10px 10px 0px 0px;
        }

        .vision-content {
            font-size: 17px;
            letter-spacing: 0.4px;
            word-spacing: 3px;
            line-height: 28px;
            margin: 20px;
            text-align: left;
        }


        /* footer styles */


        .footer-content {
            flex-direction: column-reverse;
        }

        .Footer-Links li {
            padding: 5px;
        }

        .contact-info {
            flex: 1;
            margin: 0px 20px;
            padding: 10px 10px;
            min-width: 90vw;
            margin-bottom: 20px;
        }

        .contact-info p {
            color: #cfcfcf;
        }

        .About-info {
            flex: 1;
            margin: 0px 20px;
            padding: 10px 10px;
            min-width: 90vw;
            margin-bottom: 20px;

        }

        .About-info p {
            color: #cfcfcf;
        }

        .Footer-Links {
            margin: 0px 20px;
            padding: 10px 10px;
            min-width: 20%;
            margin-bottom: 20px;

        }

        .legal-info {
            display: flex;
            justify-content: center;
            margin: 20px 20px;
            margin-bottom: 0px;
            align-items: center;
            padding: 20px 0px;

        }

        .legal-info p {
            text-align: center;
        }


        .Text {
            font-size: 17px;
            letter-spacing: 0.5px;
            line-height: 25px;
        }


    }