.Intuition {
    background-color: #f0f0f0;
    padding: 10px;
    color: #5c5c5c;
    margin-top: 8px;
    margin-bottom: 8px;
    margin-inline: 20px;
    font-weight: 500;
    padding-left: 10px;
    border-radius: 5px;
}

.IntuitionHeading {
    color: black;
    font-weight: 500;
    font-size: 18px;
    margin-top: 5px;
}

.AcitionText {
    margin-top: 1px;
    margin-bottom: 1px;
    font-size: 15px;
    margin-left: 40px;

}