/* DescriptionRenderer.css */
.description-container {
    font-family: Arial, sans-serif;
    color: #333;
    margin: 0 auto;
    padding: 20px;
    line-height: 1.6;
  }
  
  .description-container h1 {
    font-size: 24px;
    margin-bottom: 10px;
    color: #28a745; 

  }
  
  .description-container h2 {
    font-size: 20px;
    margin-top: 10px;
  }
  
  .description-container h3 {
    font-size: 18px;
    color: #444;
    margin-top: 20px;
  }
  
  .description-container strong {
    font-weight: bold;
  }
  
  .description-container em {
    font-style: italic;
  }
  
  .description-container code {
    background-color: #f8f8f8;
    padding: 2px 4px;
    border-radius: 4px;
    font-family: 'Courier New', Courier, monospace;
  }
  
  .description-container pre {
    background-color: #f8f8f8;
    padding: 10px;
    border-radius: 4px;
    font-family: 'Courier New', Courier, monospace;
    overflow-x: auto;
    margin: 10px 0;
  }
  
  .description-container ul {
    margin-top: 10px;
    list-style-type: disc;
    padding-left: 20px;
  }
  
  .description-container li {
    margin-bottom: 5px;
  }
  
  .description-container li code {
    color: #d63384; 
  }
  