.sticky-header {
    background-color: #333;
    width: 100%;
    display: flex;
    border-bottom: 3px solid #0f8bff;
    position: fixed;
    z-index: 1000;
    transition: all 0.3s ease-in-out;
}

.header-container {
    width: 100%;
    margin: 10px;
    display: flex;
    align-items: center;
    padding: 0 20px;
    flex-wrap: wrap;
}

.logo-container {
    position: absolute;
    display: flex;
    align-items: center;
    justify-content: flex-start;
}

.logo-container:hover {
    cursor: pointer;
}

.logo-img {
    height: 35px;

    @media (max-width: 768px) {
        height: 30px;
    }
}

.brand-name {
    color: white;
    font-weight: bold;
    font-size: 22px;
    margin: 0;
    margin-left: 7px;
    font-family: sans-serif;

    @media (max-width: 768px) {
        font-size: 22px;
    }
}

.menu-toggle {
    display: none;
    flex-direction: column;
    cursor: pointer;
    padding: 5px;

    @media (max-width: 768px) {
        display: flex;
    }
}

.menu-toggle .bar {
    width: 25px;
    height: 3px;
    background-color: white;
    margin: 3px 0;
    transition: all 0.3s ease-in-out;
}

.menu-toggle.open .bar:nth-child(1) {
    transform: translateY(6px) rotate(45deg);
}

.menu-toggle.open .bar:nth-child(2) {
    opacity: 0;
}

.menu-toggle.open .bar:nth-child(3) {
    transform: translateY(-6px) rotate(-45deg);
}

.navigation {
    flex-grow: 1;
    display: flex;
    /* background-color: red; */
    justify-content: center;

    @media (max-width: 768px) {
        display: none;
        flex-direction: column;
        justify-content: left;
        width: 100%;
        align-items: center;
    }
}

.navigation.active {
    display: flex;
    background-color: #333;
}

.nav-links {
    list-style: none;
    padding: 0;
    margin: 0;
    display: flex;
    justify-content: center;

    @media (max-width: 768px) {
        flex-direction: column;
        width: 100%;
    }
}

nav li {
    margin: 0 15px;

    @media (max-width: 768px) {
        margin: 10px 0;
    }
}

.Link {
    text-decoration: none;
    font-weight: bold;
    font-family: sans-serif;
    transition: color 0.3s ease, transform 0.3s ease;
    transform-origin: center;
    display: inline-block;
    font-size: 17px;
    cursor: pointer;

    @media (max-width: 768px) {
        font-size: 20px;
    }
}

.Link:hover {
    text-decoration: none;
    transform: scale(1.2);
}

@media (max-width: 768px) {
    .sticky-header {
        padding: 5px 0;
    }

    .header-container {
        flex-direction: column;
        align-items: flex-start;
        margin: 0px;
    }

    .logo-container {
        position:unset;
        margin-bottom: 10px;
    }
}