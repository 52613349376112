
.chat-container {
    width: 100%;
    max-width: 600px;
    margin: 0 auto;
    background-color: #ececec;
    color: black;
    border-radius: 8px;
    overflow: hidden;
    display: flex;
    flex-direction: column;
    height: 80vh;
  }
  
  .chat-box {
    flex: 1;
    padding: 10px;
    overflow-y: auto;
    display: flex;
    flex-direction: column;
  }
  
  .chat-message {
    padding: 5px;
    padding-inline: 10px;
    margin: 5px 0;
    border-radius: 4px;
    max-width: 80%;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  
  .chat-message.user {
    align-self: flex-end;
    background-color: #dadada;
  }
  
  .chat-message.bot {
    align-self: flex-start;
  }
  
  .chat-input {
    display: flex;
    padding-top: 10px;
    padding-bottom: 10px;
    background-color: white;
  }
  
  .chat-input input {
    flex: 1;
    padding: 10px;
    border: none;
    border-radius: 4px;
    outline: none;
    color: black;
    background-color: #ececec;
    font-size: 14px;
    border: 1px solid gray;
  }

  
  .SendButton {
    background-color:#ececec;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    padding-inline: 15px;
    border: 1px solid grey;
    border-radius: 4px;
    cursor: pointer;
    margin-left: 5px;
}