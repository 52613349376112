table {
    width: 100%;
    border-collapse: collapse;
    margin-top: 20px;
    margin-bottom: 50px;
}

p{
    text-align: left;
}

th,
td {
    border: 1px solid #ddd;
    padding: 8px;
    font-size: 14px;
}

th {
    background-color: #f2f2f2;
    text-align: left;
}

td.date-submitted,
th.date-submitted {
    width: 110px;
}

td.status,
th.status {
    width: 80px;
}