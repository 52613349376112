.SplitPane {
  transition: height 0.3s ease-in-out;
  height: 86vh;
}

p {
  color: black;
}

.horizontal {
  position: unset;
}

.Pane {
  display: flex;
  flex-direction: column;
  overflow: auto;
}

.Resizer {
  background: #0077ff;
  opacity: 0.2;
  z-index: 1;
  box-sizing: border-box;
  background-clip: padding-box;
}


.Resizer.vertical {
  width: 11px;
  margin: 1.5vh -10px;
  border-left: 5px solid rgba(255, 255, 255, 0);
  border-right: 5px solid rgba(255, 255, 255, 0);
  cursor: col-resize;
  height: 91vh;
}

.Resizer.horizontal {
  height: 5px;
  margin: 0.1vh -5px;
  cursor: row-resize;
  background: #0077ff;
  width: 100%;
}

.example-card {
  background-color: rgb(236, 236, 236);
  padding: 10px;
  border-radius: 5px;
  word-wrap: break-word;
  white-space: pre-wrap;
  overflow-x: hidden;
}

.example-card pre {
  white-space: pre-wrap;
  word-wrap: break-word;
}

.outputContainer {
  margin-inline: 20px;
}


.Header {
  background-color: #e1e1e1;
  height: 6vh;
  width: 99vw;
  display: flex;
  justify-content: left;
  align-items: end;
}


.HeaderButton {
  background-color: white;
  margin-left: 20px;
  border: 2px solid gray;
  padding: 1px 15px;
  border-radius: 3px;
  cursor: pointer;

}

.ZoneOutContainer {
  background-color: #ececec;
  height: 94vh;
  display: flex;
  flex-direction: column;
}

.ZoneOutLeftContainer {
  margin: 1.5vh;
  height: 91vh;
  border-radius: 5px;
  border: 1px solid gray;
  display: flex;
  flex-direction: column;
}

.ZoneOutRightContainer {
  background-color: white;
  margin: 1.5vh;
  height: 91vh;
  border-radius: 5px;
  border: 1px solid gray;
}

.topContainer {
  background-color: white;
  height: 6vh;
  border-top-left-radius: 5px;
  border-top-right-radius: 5px;
  display: flex;
  flex-direction: row;
  align-items: center;
  padding-inline: 2px;

}


.OptionsContainer {
  background-color: white;
  height: 6vh;
  border-top-left-radius: 5px;
  border-top-right-radius: 5px;
  display: flex;
  padding-top: 10px;
  align-items: center;
  border-bottom: 1px solid black;
}

.SelectedOption {
  border-bottom: 2px solid black;
  height: 100%;
  display: flex;
  align-items: center;
}


.BottomBarStyle {
  z-index: 999;
  background-color: white;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  padding-inline: 4px;
  position: absolute;
  bottom: 7vh;
  left: 10px;
  right: 10px;
  border-top: 1px solid gray;
  padding-block: 3px;
}

.Button {
  cursor: pointer;
  padding-inline: 10px;
  border: 2px solid gray;
  border-radius: 4px;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 30px;
  font-weight: 500;
  cursor: 'pointer',
}