.spinner {
    border: 4px solid rgba(0, 0, 0, 0.1);
    border-top: 4px solid #2b87dd;
    border-left: 4px solid #2b87dd;
    border-right: 4px solid #2b87dd;
    border-radius: 50%;
    width: 30px;
    height: 30px;
    animation: spin 1s linear infinite;
}

@keyframes spin {
    0% {
        transform: rotate(0deg);
    }

    100% {
        transform: rotate(360deg);
    }
}