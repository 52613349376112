.TestCase {
    display: flex;
    align-items: center;
    padding-inline: 10px;
    padding-block: 1px;
    border: 2px solid gray;
    border-radius: 5px;
    margin-inline: 10px;
    cursor: pointer;
}
.TestCaseText {
    margin: 0;
    padding: 0;
    font-size: 14px;
    font-weight: bold;
}

.input-container {
    display: flex;
    flex-direction: column;
    padding: 10px;
    border-radius: 5px;
}

.input-label {
    color: black;
    font-size: 16px;
    margin-right: 10px;
}

.styled-input {
    background-color: rgb(231, 231, 231);
    color: gray;
    border: none;
    border-radius: 4px;
    padding: 8px 12px;
    width: 100%;
    font-size: 16px;
    outline: none;
    transition: border-color 0.2s;
}

.styled-input:focus {
    border: 1px solid #4a90e2;
    /* Blue border on focus */
}