/* TopQuestions.css */
.container {
    margin: 0 auto;
    padding: 20px;
}

.QuestionContainer {
    border-bottom: 1px solid #aeaeae;
    cursor: pointer;
    transition: all 0.1s ease-in-out;
    margin-bottom: 20px;
}

.question {
    display: flex;
    justify-content: space-between;
    align-items: center;
    color: #333;
    transition: color 0.1s ease;
    font-weight: bold;
    padding: 0;
    padding-block: 10px;
    padding-inline: 20px;
    cursor: pointer;
    border-radius: 5px;
}

.question:hover {
    color: #148bec;
}

.QuestionText {
    font-weight: 500;
    display: inline-block;
}

.icon {
    font-size: 18px;
    transition: transform 0.1s ease;
}

.answer {
    padding: 0;
    margin: 0;
    padding-inline: 20px;
    padding-block: 10px;
    background-color: #d9ffda;
    border-radius: 4px;
    color: #666;
    transition: all 0.3s ease;
}