.outputContainer {
    display: flex;
    height: 100%;
    padding-top: 10px;
}

.TestCase {
    display: flex;
    align-items: center;
    padding-inline: 10px;
    padding-block: 1px;
    border: 2px solid gray;
    border-radius: 5px;
    margin-inline: 10px;
    cursor: pointer;
}
.TestCaseText {
    margin: 0;
    padding: 0;
    font-size: 14px;
    font-weight: bold;
}